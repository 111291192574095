@if $password_strengthen {
  .sign-in-page {
    .form-item {
      &.password-field {
        overflow: visible;
      }
    }
  }
  .loyalty-offer-overlay {
    .password-field {
      &__rules {
        li {
          color: $color-dark-gray;
          &.pass {
            color: $color-green;
            &::before {
              content: $tick;
              color: $color-green;
            }
          }
        }
      }
    }
  }
  #registration {
    .profile-password-update {
      &__fieldset {
        .current_password_label {
          display: block;
        }
      }
    }
  }
  .registration-page {
    &__content {
      .profile-info {
        &__change_pwd_link {
          margin: 13px 0 20px;
        }
      }
    }
  }
  .password-field {
    &__info {
      width: 200px;
      #signin & {
        width: 210px;
        &-signin {
          @media #{$medium-up} {
            position: relative;
            border: none;
            #{$rdirection}: 0;
            top: 0;
            display: flex !important;
            &::before {
              content: none;
            }
          }
        }
      }
      &-checkout {
        #{$ldirection}: 42%;
        -webkit-transform: translateY(5%);
        -moz-transform: translateY(5%);
        -o-transform: translateY(5%);
        transform: translateY(5%);
        margin-top: 5px;
        @media #{$medium-up} {
          top: 0;
          position: absolute;
          #{$rdirection}: 42%;
          border: 1px solid $color-black;
          -webkit-transform: translateY(-33%);
          -moz-transform: translateY(-33%);
          -o-transform: translateY(-33%);
          transform: translateY(-33%);
        }
        &::before {
          @media #{$medium-up} {
            content: '';
            position: absolute;
            top: 50px;
            #{$rdirection}: 100%;
            border: 5px solid;
            border-color: transparent $color-dark-gray transparent transparent;
          }
        }
      }
      #confirm & {
        @media #{$medium-up} {
          width: 200px;
          top: 0;
          -webkit-transform: translateY(-33%);
          -moz-transform: translateY(-33%);
          -o-transform: translateY(-33%);
          transform: translateY(-33%);
        }
      }
      #password_reset & {
        @media #{$medium-up} {
          top: -13px;
        }
      }
    }
    &__rules {
      font-size: 10px;
      #signin & {
        @media #{$medium-up} {
          font-size: 12px;
          column-count: 2;
        }
      }
      .checkout & {
        @media #{$medium-up} {
          column-count: 1;
        }
      }
    }
  }
}
