.product-foundation-vto {
  &__container {
    ul.shade-list-vto {
      li {
        .vto-filter-text {
          font-size: 11px;
        }
      }
    }
  }
  &__mobile-ctrl {
    .container {
      .product-sub-heading,
      .product-name {
        font-size: 12px;
      }
    }
  }
}
