.click-and-collect-method-select {
  float: left;
  margin-bottom: 20px;
  margin-top: 15px;
  @media #{$small-only} {
    margin-bottom: 15px;
    margin-top: 0;
  }
  .radio-button {
    float: left;
    width: 65%;
    @media #{$small-only} {
      width: 100%;
    }
  }
  a.button {
    border: solid 1px $color-navy;
    font-size: 12px;
    letter-spacing: 0.15em;
    cursor: pointer;
    font-weight: 700;
    padding: 10px 20px;
    line-height: inherit;
  }
  .auspost-logo {
    float: right;
    width: 35%;
    @media #{$small-only} {
      display: none;
    }
  }
  label {
    text-transform: none;
    span {
      display: inline-block;
    }
    &::before {
      float: left;
      margin-top: 3px;
    }
  }
  .auspost-label {
    font-weight: bold;
    max-width: 400px;
    float: left;
    @media #{$small-only} {
      max-width: 90%;
    }
  }
  .auspost-note {
    display: inline-block;
    margin-left: 22px;
    margin-top: 10px;
  }
  .auspost-overlay.button {
    margin: 15px;
    @media #{$small-only} {
      margin: 20px;
    }
  }
  .auspost-address-display {
    @media #{$small-only} {
      margin-bottom: 15px;
    }
    p {
      margin-top: 0;
    }
  }
}

#colorbox.auspost-overlay {
  .auspost-continue {
    width: 100%;
    .location-selected {
      margin: 20px auto;
      display: block;
      width: 150px;
      border: solid 1px $color-navy;
      font-size: 12px;
      letter-spacing: 0.15em;
      cursor: pointer;
      font-weight: 700;
      padding: 10px 20px;
      line-height: inherit;
    }
  }
  #cboxClose {
    top: 2px;
    right: 9px;
    &:before {
      font-size: 22px;
    }
  }
}
