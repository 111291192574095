.sign-in-page,
.signin-overlay-wrapper {
  .sign-in-component {
    .signin-overlay {
      &__toggle-form {
        font-size: 12px;
      }
    }
    .forgot-password {
      .error_messages_display {
        color: $color-red;
      }
    }
    color: $color-navy;
    @media #{$medium-up} {
      padding: 30px;
      width: 585px;
    }
    &__form {
      margin: 0;
      .field[type='text'],
      .field[type='email'],
      .field[type='password'],
      .field[type='tel'],
      .form-text,
      .form-submit {
        @include box-shadow(none);
        @include input-placeholder {
          line-height: normal;
        }
        width: 100%;
        height: 40px;
        @media #{$small-only} {
          @include input-placeholder {
            color: $color-light-gray;
            background-color: $color-white;
          }
          height: 48px;
          border-color: $color-light-gray;
          &.error {
            border-color: $color-red;
          }
        }
      }
      &--sign-in,
      &--registration {
        padding: 0 30px;
        @media #{$medium-up} {
          padding: 0;
        }
      }
      &-content {
        @media #{$medium-up} {
          margin: 0 30px;
          padding: 0 30px;
        }
        .sign-in-component__header {
          margin: 8px 0;
          width: auto;
          font-family: $optimalight;
          font-weight: normal;
          font-size: 40px;
          letter-spacing: -0.05em;
        }
        .sign-in-component {
          .form-submit {
            margin-bottom: 0;
          }
          &__password-wrapper {
            @include swap_direction(margin, 0 0 10px);
            position: relative;
            .form-text {
              margin: 0;
            }
            .visible-pass-wrapper {
              .form-text {
                @media #{$medium-up} {
                  border-color: $color-black;
                  color: $color-black;
                }
              }
            }
            .password-field__info {
              display: flex !important;
            }
          }
          &__show-password-wrapper {
            position: absolute;
            #{$rdirection}: 12px;
            top: 2px;
            @media #{$small-only} {
              top: 15px;
            }
            label {
              &:before,
              &:after {
                visibility: hidden;
              }
            }
          }
          &__registration-options {
            width: auto;
            .sign-in-component {
              &__email-promos {
                display: none;
              }
            }
          }
          &__header {
            font-family: $font-bauerbodoni-roman;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 0.02em;
            @media #{$medium-up} {
              text-align: #{$ldirection};
            }
          }
        }
        .text--form-help {
          margin-bottom: 10px;
          label {
            letter-spacing: 0.05em;
          }
        }
        .text--checkbox-label {
          label {
            &.error a {
              color: $color-red;
            }
          }
        }
        h6 {
          font-family: $akzidenz;
          margin-bottom: 10px;
          letter-spacing: 0.05em;
        }
      }
      &-loyalty {
        display: none;
      }
    }
    &.signin-join-loyalty {
      .sign-in-component {
        &__confirm--join-loyalty {
          display: block;
        }
      }
      &.sign-in {
        .sign-in-component__form--sign-in {
          display: none;
        }
      }
    }
    &__confirm--registration,
    &__confirm--join-loyalty {
      display: none;
    }
  }
  .error_messages {
    text-align: #{$ldirection};
  }
  .sign-in-component__form-content {
    padding: 0;
    .signin-loyalty-cta {
      .signin-loyalty-cta {
        &__button {
          margin: 20px 0;
        }
        &__header {
          margin: 8px 0;
          width: auto;
          font-family: $font-bauerbodoni-roman;
          font-weight: 400;
          font-size: 38px;
          letter-spacing: -0.05em;
          text-transform: uppercase;
        }
        &__terms {
          width: auto;
        }
        &__sub-header {
          padding-bottom: 30px;
        }
        &__link {
          font-weight: 700;
        }
      }
    }
  }
}

.signin-overlay {
  &-wrapper {
    &.new-user-registration {
      width: 100% !important;
      @media #{$medium-up} {
        height: 530px !important;
        width: 556px !important;
      }
      #cboxContent,
      #cboxWrapper {
        width: 100% !important;
        @media #{$medium-up} {
          width: 556px !important;
        }
      }
      &#cboxOverlay {
        border: none;
      }
    }
    &.signin-overlay-loyalty {
      border: 1px solid $color-medium-gray;
      .sign-in-component {
        @media #{$medium-up} {
          width: 546px;
        }
        &__form {
          margin: 0;
          padding: 0;
          .signin-overlay__toggle-form {
            display: none;
          }
          &-content {
            @media #{$small-only} {
              @include swap_direction(padding, 30px 30px 0);
            }
          }
        }
        &--loyalty {
          @media #{$medium-up} {
            padding: 30px 10px;
          }
          &.fb-enabled {
            &.sign-in {
              @media #{$medium-up} {
                height: 590px !important;
              }
            }
          }
        }
      }
      &#colorbox {
        position: absolute !important;
      }
      #cboxContent {
        padding: 0;
        #cboxClose,
        #cboxClose:hover {
          #{$rdirection}: 20px;
          top: 20px;
        }
      }
      #cboxLoadedContent {
        @media #{$small-only} {
          width: 100% !important;
          height: 100% !important;
          overflow: scroll !important;
        }
      }
      .sign-in-component--loyalty {
        &.registration {
          &.sign-in {
            .signin-overlay__toggle {
              .signin-overlay__toggle {
                &-form {
                  &--have-account {
                    display: none;
                  }
                  &--dont-have-account {
                    display: block;
                    text-align: #{$ldirection};
                    font-size: 12px;
                  }
                }
              }
            }
          }
          .signin-overlay__toggle {
            .signin-overlay__toggle {
              &-form {
                &--have-account {
                  display: block;
                  text-align: #{$ldirection};
                  font-size: 12px;
                }
                &--dont-have-account {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  &__toggle-form {
    margin: 20px 0;
    padding: 0 30px;
    text-align: center;
    &--have-account {
      display: block;
      &-signin {
        font-size: 12px;
        color: $color-navy;
        background: transparent;
      }
      .sign-in & {
        display: none;
      }
    }
    &--dont-have-account {
      display: none;
      &-register,
      &-signin {
        font-size: 12px;
        color: $color-navy;
        background: transparent;
      }
      .mobile_hidden {
        @media #{$medium-up} {
          display: inline;
        }
      }
    }
  }
}
/* Account sign in page */
.sign-in-page--loyalty {
  max-width: 1366px;
  margin: 0 auto;
  .sign-in-page--loyalty {
    &-container-banner {
      display: none;
      @media #{$medium-up} {
        width: 54%;
        float: #{$ldirection};
        display: block;
        background-image: url('/media/loyalty/tablet-potrait.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        height: 680px;
      }
      @media #{$large-up} {
        background-image: url('/media/loyalty/tablet-landscape.jpg');
        height: 825px;
      }
      @media #{$xlarge-up} {
        width: 50%;
        background-image: url('/media/loyalty/account-login.jpg');
        max-width: 668px;
      }
    }
    &--header {
      display: none;
      @media #{$medium-up} {
        font-size: 3.6vw;
        font-family: $font-bauerbodoni-roman;
        letter-spacing: 0.09em;
        text-align: center;
        color: $color-white;
        padding-top: 44vw;
        display: block;
      }
      @media #{$large-up} {
        padding-top: 41vw;
      }
      @media #{$xlarge-up} {
        padding-top: 28vw;
      }
      @media #{$xxlarge-up} {
        font-size: 50px;
        padding-top: 380px;
      }
    }
    &--sub--content {
      display: none;
      @media #{$medium-up} {
        margin: 0;
        text-align: center;
        color: $color-white;
        font-weight: 500;
        font-size: 1.3vw;
        display: block;
      }
      @media #{$xxlarge-up} {
        font-size: 18px;
      }
    }
  }
  .sign-in-component--loyalty {
    @media #{$medium-up} {
      width: 46%;
      padding: 15px;
      max-width: 574px;
    }
    @media #{$large-up} {
      padding: 20px;
    }
    &.registration {
      &.sign-in {
        .signin-overlay__toggle-form {
          &--have-acount {
            display: none;
          }
          &--dont-have-acount {
            display: block;
            @media #{$medium-up} {
              padding: 0;
              text-align: #{$ldirection};
              font-size: 12px;
            }
          }
        }
      }
      .signin-overlay__toggle-form {
        margin: 15px 0;
        &--have-acount {
          display: block;
          @media #{$medium-up} {
            padding: 0;
            text-align: #{$ldirection};
            font-size: 12px;
          }
        }
        &--dont-have-acount {
          display: none;
        }
      }
    }
    .sign-in-component {
      &__form {
        margin: 0;
        .field[type='text'],
        .field[type='email'],
        .field[type='password'],
        .field[type='tel'],
        .form-text,
        .form-submit {
          @include box-shadow(none);
          @media #{$medium-up} {
            width: 90%;
            height: 4vw;
            line-height: 0;
          }
          @media #{$large-up} {
            width: 100%;
            height: 40px;
          }
          @media #{$xlarge-up} {
            width: 85%;
            height: 40px;
          }
        }
      }
      &__password-wrapper {
        position: relative;
      }
      &__show-password-wrapper {
        position: absolute;
        top: 12px;
        #{$rdirection}: 12px;
        @media #{$medium-up} {
          top: 0;
          #{$rdirection}: 22%;
        }
        .text--checkbox-label {
          &:after,
          &:before {
            display: none;
          }
        }
      }
      &__registration-options {
        @media #{$medium-up} {
          width: 100%;
        }
        .text--form-help {
          @media #{$medium-up} {
            width: 85%;
          }
        }
        .sign-in-component {
          &__email-promos {
            display: none;
          }
        }
      }
      &__header {
        width: auto;
        font-family: $font-bauerbodoni-roman;
        text-transform: uppercase;
        text-align: center;
        font-size: 30px;
        font-weight: 100;
        letter-spacing: 0.02em;
        line-height: 1;
        @media #{$medium-up} {
          text-align: #{$ldirection};
          font-size: 25px;
          margin-bottom: 10px;
        }
        @media #{$large-up} {
          font-size: 38px;
          width: 100%;
        }
        @media #{$xlarge-up} {
          font-size: 40px;
          width: 83%;
        }
      }
      &__subheader {
        margin-bottom: 10px;
        font-size: 15px;
        letter-spacing: 0.05em;
        font-family: $akzidenz;
        @media #{$medium-up} {
          width: 85%;
        }
      }
    }
  }
  .social-login {
    .social-login {
      &__container {
        padding: 0 30px;
        @media #{$medium-up} {
          padding: 0;
          margin: 0;
          width: 100%;
          text-align: center;
        }
        @media #{$xlarge-up} {
          width: 85%;
        }
      }
      &__email-opt-in {
        padding-top: 15px;
        @media #{$medium-up} {
          text-align: #{$ldirection};
          width: 100%;
        }
        @media #{$xlarge-up} {
          width: 85%;
        }
      }
      &__divider {
        @media #{$medium-up} {
          width: 100%;
        }
      }
    }
  }
}

.sign-in-page {
  &.sign-in-page--loyalty {
    .sign-in-component__confirm {
      &.sign-in-component__confirm {
        &--join-loyalty {
          padding: 0 20px;
          display: block;
          text-align: center;
          @media #{$medium-up} {
            margin-top: 25%;
            text-align: #{$ldirection};
          }
          .signin-loyalty-cta {
            &__button {
              color: $color-white;
              @media #{$medium-up} {
                width: 150px;
              }
            }
          }
          .error_messages {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
    .sign-in-component {
      .form-submit {
        margin-bottom: 10px;
      }
    }
  }
}
/* My feed Section */
.drawer-formatter {
  &__content {
    .my-feed-tray--loyalty {
      &:before {
        width: 100%;
      }
      .my-feed-tray {
        &__inner {
          .my-feed-tray__dual-col {
            padding: 30px 0;
            width: 100%;
          }
          .my-feed-tray__col {
            &.my-feed-summary {
              display: none;
            }
          }
        }
      }
      .my-feed-loyalty-container {
        max-width: 600px;
        .loyalty-offer {
          max-width: 600px;
          &__img {
            @include swap_direction(padding, 1px 20px 0 0);
            float: #{$ldirection};
          }
          &__elist-header {
            @include headline--quinary--plain;
            line-height: 0.97;
          }
          &__right-container {
            overflow: hidden;
          }
        }
        .my-feed-loyalty-raf {
          margin-bottom: 5px;
        }
      }
      .my-feed-loyalty {
        &__right-container {
          overflow: hidden;
          .my-feed-loyalty {
            &__header {
              font-size: 20px;
              line-height: 1;
              @media #{$large-up} {
                font-size: 22px;
                line-height: 1.2;
              }
            }
            &__status-display {
              line-height: 1;
              @media #{$large-up} {
                line-height: 1.4;
              }
            }
          }
        }
        &__status-display {
          @include swap_direction(padding, 0px 10px 5px 0);
          float: none;
          width: 100%;
          border-#{$rdirection}: none;
          letter-spacing: 0.02em;
        }
        &__zero-points {
          padding: 0;
          @media #{$medium-up} {
            letter-spacing: 0.05em;
            width: 100%;
          }
          @media #{$large-up} {
            letter-spacing: 0.15em;
            width: 66%;
          }
        }
        &__status-container {
          margin: 5px 0;
        }
        &__status-next {
          width: 95%;
          padding-#{$ldirection}: 0;
          .next-level {
            display: none;
          }
        }
      }
    }
  }
  &__close {
    #{$rdirection}: 15px;
  }
}

.registration-confirmation {
  .social-login,
  .signin-overlay__toggle {
    display: none;
  }
}

.signin-join-loyalty {
  .signin-overlay__toggle {
    display: none;
  }
}

.page-footer {
  clear: both;
}

.page-utilities {
  .page-utilities {
    &__signin-text {
      display: inline-block;
      cursor: pointer;
    }
  }
}

.loyalty_popover {
  .loyalty_popover {
    &__checkbox-label {
      font-size: 12px;
      letter-spacing: 0.05em;
    }
  }
}

.loyalty-offer-overlay {
  border: 1px solid $color-medium-gray;
}

.elc-user-state-logged-in {
  .page-utilities {
    .page-utilities,
    .utility-nav {
      &__signin-text {
        display: none;
      }
    }
  }
}

.field-mobile-template {
  .loyalty_market {
    .loyalty_market {
      &__excited__benefits,
      &__excited__enthusiastic__benefits {
        font-size: 15px;
        text-transform: inherit;
      }
    }
  }
}

.signin-overlay-loyalty {
  #cboxLoadedContent {
    .sign-in-component {
      height: auto;
      overflow: hidden;
    }
  }
}
