#loyalty__panel__transactions td {
  border: none;
  border-bottom: 1px solid $color-light-gray;
}

#loyalty__content {
  color: $color-navy;
  .chart {
    p {
      color: $color-navy;
      font-size: 15px;
    }
  }
  #loyalty__panel__notification {
    &.is-user {
      &.is-member {
        background: url('/media/loyalty/account-page-loyal-mobile.jpg');
        height: 47vw;
        background-size: cover;
        @media #{$medium-up} {
          background: url('/media/loyalty/account-page-loyal-pc.jpg') no-repeat;
          background-size: contain;
          height: 26vw;
        }
        @media #{$xxlarge-up} {
          max-width: 1366px;
          height: 360px;
        }
      }
    }
  }
  .points_to_next_earn_before {
    display: none;
    @media #{$medium-up} {
      display: block;
      clear: both;
      padding-top: 25px;
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
    }
    a {
      display: none;
      @media #{$medium-up} {
        display: block;
        text-transform: uppercase;
        margin-top: 10px;
      }
    }
  }
  #loyalty__panel__transactions {
    padding: 0 20px;
    @media #{$medium-up} {
      margin-bottom: 30px;
    }
    .loyalty__panel__transactions {
      &__current-tier,
      &__available-amount {
        display: none;
      }
      &__list__column-activity {
        display: table-cell;
        &__points-earned {
          @media #{$medium-up} {
            display: none;
          }
        }
      }
      &__title {
        border-bottom: 0;
        border-top: 1px solid $color-light-gray;
        padding-top: 15px;
        margin-bottom: 0;
        @media #{$medium-up} {
          border-top: 0;
          border-bottom: 1px solid $color-light-gray;
        }
      }
      &__table {
        margin-bottom: 20px;
        @media #{$medium-up} {
          margin-bottom: 0;
        }
        th {
          font-size: 12px;
          text-align: #{$ldirection};
          border-bottom: 1px solid $color-light-gray;
          letter-spacing: 0.15em;
          display: table-cell;
        }
        .loyalty__panel__transactions {
          &__list__column-total,
          &__list__column-plus {
            display: none;
          }
          &__list__column-earned {
            display: none;
            @media #{$medium-up} {
              width: auto;
              display: table-cell;
            }
          }
        }
      }
      &__accordian {
        width: 45px;
        height: 45px;
        display: inline-block;
        float: #{$rdirection};
        position: relative;
        margin-#{$rdirection}: 50px;
        bottom: 15px;
        background: url(/media/loyalty/sprites-icons.png);
        background-position: 0 -2957px;
        &.open {
          background-position: 0 -3269px;
        }
        @media #{$medium-up} {
          display: none;
        }
      }
    }
  }
  #loyalty__panel__benefits {
    padding: 0 20px;
    .loyalty__current {
      &__tier_1 {
        .loyalty__panel {
          &__benefits__tier--1,
          &__benefits__tier--2,
          &__benefits__tier--3 {
            .loyalty__panel {
              &__benefits__description {
                color: $color-black;
              }
            }
          }
        }
      }
      &__tier_2 {
        .loyalty__panel {
          &__benefits__tier--1,
          &__benefits__tier--2,
          &__benefits__tier--3 {
            .loyalty__panel {
              &__benefits__description {
                color: $color-black;
              }
            }
          }
        }
      }
      &__tier_3 {
        .loyalty__panel {
          &__benefits__tier--1,
          &__benefits__tier--2,
          &__benefits__tier--3 {
            .loyalty__panel {
              &__benefits__description {
                color: $color-black;
              }
            }
          }
        }
      }
    }
    .loyalty__panel__benefits {
      &__description {
        @include swap_direction(padding, 0 20px 25px);
        background: $color-white;
        min-height: auto;
        border-bottom: 1px solid $color-navy;
        @media #{$medium-up} {
          border-bottom: 0;
          color: $color-lighter-gray-alt;
        }
        &-list {
          li {
            list-style: square;
            line-height: 1.6;
            padding: 0;
          }
          .loyalty__panel__benefits__unlock {
            display: none;
          }
        }
        &-list__overlay {
          position: static;
          padding: 0;
          .loyalty__panel__benefits__unlock {
            padding: 0;
          }
        }
      }
      &__container {
        display: block;
        margin-top: 0px;
      }
      &__tier {
        width: 100%;
        opacity: 1;
        text-align: #{$ldirection};
        @media #{$xlarge-up} {
          width: 33.3%;
          padding-#{$rdirection}: 40px;
        }
        &-level {
          text-transform: uppercase;
          padding: 10px 0;
          color: $color-navy;
          font-family: $font-bauerbodoni-roman;
          letter-spacing: 0.02em;
          @media #{$medium-up} {
            @include swap_direction(padding, 0 0 8px);
            border-bottom: 1px solid $color-navy;
            margin-bottom: 18px;
          }
        }
        &-header {
          padding-bottom: 5px;
          &.default_tier,
          &.on_hover {
            background: none;
            padding-bottom: 5px;
          }
        }
        &.next_tier {
          opacity: 1;
        }
        &--3 {
          .loyalty__panel__benefits__description {
            border-bottom: 0;
            padding: 0 18px 25px;
          }
        }
      }
      &__title {
        padding-top: 20px;
        border-top: 1px solid $color-light-gray;
        border-bottom: 0;
        @media #{$medium-up} {
          border-top: 0;
          border-bottom: 1px solid $color-light-gray;
        }
      }
      &__accordian {
        width: 45px;
        height: 45px;
        display: inline-block;
        float: #{$rdirection};
        position: relative;
        margin-#{$rdirection}: 50px;
        bottom: 15px;
        background: url('/media/loyalty/sprites-icons.png');
        background-position: 0 -2957px;
        &.open {
          background-position: 0 -3269px;
        }
        @media #{$medium-up} {
          display: none;
        }
      }
    }
  }
  .loyalty-landing-menu {
    display: none;
  }
  #loyalty__page__index {
    background-image: none;
    margin-top: 0;
    padding-top: 0;
    .account-breadcrumb-wrapper {
      top: 35vw;
    }
    .loyalty__page__index {
      &__breadcrumb {
        padding: 0;
        height: 0;
      }
    }
    #loyalty__panel {
      &__offers__sku {
        &.is-selected {
          .loyalty__panel__offers {
            &__sku__cta {
              .loyalty__panel__offers {
                &__sku__remove {
                  background: $color-navy;
                  color: $color-white;
                  height: 35px;
                  line-height: 3;
                  width: 120px;
                  text-decoration: none;
                  margin: 0 auto;
                }
              }
            }
          }
          .loyalty__panel {
            &__product__button {
              color: $color-white;
              background: $color-medium-gray;
              border-color: $color-medium-gray;
              visibility: visible;
              position: relative;
              top: 35px;
            }
          }
        }
      }
    }
  }
  .e-list_not_signed {
    margin-top: 0;
    .title_main {
      visibility: visible;
      font-size: 2.9vw;
      color: $color-navy;
      font-family: $font-bauerbodoni-roman;
      letter-spacing: 0.01em;
      line-height: 3.9vw;
      text-transform: uppercase;
      padding-top: 4vw;
      b {
        font-weight: normal;
      }
      @media #{$xxlarge-up} {
        font-size: 40px;
        padding-top: 55px;
        line-height: 1.2;
      }
    }
    .title_subheader {
      visibility: visible;
      font-weight: normal;
      font-size: 1.1vw;
      line-height: 1.5vw;
      letter-spacing: 0.15em;
      @media #{$xxlarge-up} {
        font-size: 15px;
        line-height: 1.6;
      }
    }
    .sub_title {
      font-size: 16px;
      font-family: $akzidenz;
      text-transform: uppercase;
      line-height: 1.3;
      width: 100%;
      letter-spacing: 0.15em;
      padding-top: 20px;
      @media #{$medium-up} {
        font-weight: bold;
        font-size: 0.88vw;
        line-height: 2vw;
        padding-top: 8px;
        letter-spacing: 0.15em;
      }
      @media #{$xxlarge-up} {
        font-size: 12px;
        line-height: 1.2;
      }
    }
    .e-list_not_signed__text {
      display: block;
      font-size: 16px;
      width: 100%;
      line-height: 1.2;
      margin-top: 0;
      @media #{$medium-up} {
        width: 78%;
        margin: 0 auto;
        font-size: 1.1vw;
        line-height: 1.5vw;
      }
      @media #{$xxlarge-up} {
        font-size: 15px;
        line-height: 1.6;
      }
    }
    .e-list_not_signed-right__text {
      display: block;
      font-size: 16px;
      line-height: 1.2;
      margin: 0 10px;
      padding-bottom: 15px;
      text-transform: initial;
      @media #{$medium-up} {
        margin: 0 10px;
        font-size: 1.1vw;
        line-height: 1.2vw;
        padding: 0;
        letter-spacing: 0.05em;
      }
      @media #{$xxlarge-up} {
        font-size: 15px;
        line-height: 1.6;
      }
    }
    .btn_join {
      color: $color-white;
      background: $color-navy;
      border: 0;
      padding: 20px;
      font-size: 16px;
      line-height: 0;
      margin: 10px 0;
      @media #{$medium-up} {
        padding: 0 1vw;
        font-size: 0.9vw;
        height: 3vw;
        line-height: 3vw;
        margin: 0.3vw;
      }
      @media #{$xxlarge-up} {
        padding: 0 13px;
        font-size: 12px;
        height: 40px;
        line-height: 3;
        margin: 4px;
      }
    }
    &-right {
      .footnote {
        display: none;
      }
      &__button-container {
        padding-top: 0.7vw;
        @media #{$xxlarge-up} {
          padding-top: 10px;
        }
        .loading {
          background-color: transparent;
        }
      }
    }
    &__link {
      font-size: 15px;
      @media #{$medium-up} {
        font-size: 0.8vw;
        line-height: 1.5vw;
      }
      @media #{$xxlarge-up} {
        font-size: 12px;
        line-height: 1.6;
      }
    }
    &__link-container {
      margin-top: 0.7vw;
    }
  }
  .e-list_not_signed_wrapper {
    background-color: transparent;
    text-align: center;
    @media #{$medium-up} {
      background: url('/media/loyalty/account-page-non-pc.jpg') no-repeat;
      backround-size: contain;
      height: 31vw;
      @media #{$xxlarge-up} {
        height: 411px;
        max-width: 1052px;
      }
    }
    .e-list_not_signed-left {
      width: 100%;
      padding: 0 10px;
      @media #{$medium-up} {
        @include swap_direction(margin, 0 0 0 56%);
        background: none;
        float: none;
        width: 44%;
        padding: 0;
        position: static;
      }
      &__inner {
        @media #{$medium-up} {
          background: none;
          min-height: auto;
          padding-bottom: 10px;
        }
      }
    }
    .e-list_not_signed-right {
      width: 100%;
      @media #{$medium-up} {
        @include swap_direction(margin, 0 0 0 58%);
        background: none;
        float: none;
        width: 40%;
        padding: 0;
        position: static;
      }
    }
  }
  .e-list_signed-left {
    padding: 10px 0;
  }
}
/* NON LOYALTY */
#loyalty__panel__points {
  .disclaimer {
    display: block;
  }
  &.account-e-list {
    padding: 0;
    clear: both;
    &.is-user {
      &.is-member {
        padding: 0 25px;
        @media #{$medium-up} {
          padding: 0 20px;
        }
        .disclaimer {
          display: none;
        }
        .loyalty__panel__points__title--loyalty {
          width: 100%;
          margin: 0;
          text-indent: 0;
          padding: 15px 0;
        }
      }
    }
  }
}

#loyalty__page__tout {
  #loyalty__panel__points {
    padding: 0 25px;
    @media #{$medium-up} {
      padding: 0;
    }
    .loyalty__panel__points__title--loyalty {
      border-bottom: 1px solid $color-light-gray;
      padding-bottom: 5px;
      display: block;
      margin: 0;
      text-indent: 0;
      width: 100%;
      @media #{$medium-up} {
        padding: 0 0 5px;
        margin-top: 80px;
        border-bottom: 0;
      }
    }
  }
}

#index {
  margin-#{$ldirection}: 0;
  width: 100%;
  &.elc-user-state-loyalty {
    .page-navigation__menu-toggle,
    .page-utilities__search-button,
    .page-utilities.clearfix {
      display: inline-block;
    }
  }
}

#loyalty__panel__offers {
  padding-#{$ldirection}: 20px;
  .loyalty__panel__offers {
    &__offer-wrapper {
      width: 140px;
      display: flex;
      flex-direction: column;
      margin: inherit;
      @media #{$medium-up} {
        width: 230px;
        text-align: inherit;
        min-height: 420px;
      }
    }
    &__select {
      padding-top: 10px;
    }
    &__offer-header {
      font-family: $akzidenz;
      font-size: 15px;
      line-height: 1.6;
      letter-spacing: 0.15em;
      text-align: center;
      padding-top: 15px;
      order: 3;
    }
    &__button-container {
      text-align: center;
    }
    &__offer-image-container {
      width: 100px;
      margin: 0 auto;
      order: 1;
      @media #{$medium-up} {
        width: 144px;
      }
      .checkout__offer-code__wallet-item__image {
        width: 120px;
        height: 198px;
        background: $color-navy;
        &__title {
          color: $color-white;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          height: 198px;
          font-size: 20px;
          font-family: $font-bauerbodoni-roman;
          text-transform: uppercase;
          line-height: 1.1;
          letter-spacing: 0.02em;
          padding: 0 5px;
          word-break: break-word;
          @media #{$medium-up} {
            font-size: 24px;
          }
        }
        @media #{$medium-up} {
          width: 144px;
        }
      }
    }
    &__list {
      .slick-prev {
        #{$ldirection}: 0;
        z-index: 1;
        top: 25%;
        @media #{$medium-up} {
          #{$ldirection}: 20px;
        }
      }
      .slick-next {
        #{$rdirection}: 0;
        top: 26%;
        @media #{$medium-up} {
          #{$rdirection}: 20px;
        }
      }
      .slick-dots {
        display: none;
      }
    }
    &__title {
      margin: 0;
      width: 100%;
      text-indent: 0;
    }
    &__button {
      color: $color-white;
      background-color: $color-navy;
    }
    &__offer-description {
      font-size: 15px;
      line-height: 1.6;
      letter-spacing: 0.05em;
      font-family: $akzidenz;
      text-align: center;
      padding-top: 15px;
      order: 2;
    }
  }
}

.checkout__index-content {
  .social-login__divider {
    position: relative;
  }
}

.not_loyalty_member {
  #loyalty__content {
    .account-e-list {
      background: none;
      .e-list_not_signed {
        &_mobile_banner {
          background: url('/media/loyalty/account-page-non-mobile.jpg');
          height: 43vw;
          background-size: cover;
          @media #{$medium-up} {
            display: none;
          }
        }
        &-right {
          margin-top: 25px;
          &__button-container {
            padding: 0 40px;
          }
          .footnote {
            display: none;
          }
        }
      }
    }
  }
}

.account-body {
  &.sidebar-page__content {
    .account-page__header {
      margin-bottom: 0;
    }
  }
}

.account-page__loyalty {
  #loyalty__panel__points {
    .loyalty__panel__points__cta {
      display: block;
      padding: 0 25px;
      margin: 25px 0;
    }
  }
}

.account-wishlist {
  .section-head {
    @media #{$medium-up} {
      padding-bottom: 15px;
    }
  }
}

#loyalty__panel {
  &__offers__sku {
    padding-#{$ldirection}: 20px;
    .slick-prev {
      z-index: 1;
    }
  }
}

.account-utilities {
  .account-utilities {
    &__welcome-msg {
      letter-spacing: 0.05em;
    }
  }
}

#loyalty__panel__offers__sku {
  .button--offers {
    background: $color-navy;
    color: $color-white;
    &.loyalty__panel__offers {
      &__sku__selected {
        display: none !important;
      }
    }
  }
}

.shipping-panel {
  .loyalty_join {
    .pc_loyalty {
      &_join_container {
        display: none;
      }
    }
  }
}

.elc-user-state-loyalty {
  .loyalty__content-banner {
    background: url('/media/loyalty/account-page-loyal-mobile.jpg');
    height: 47vw;
    background-size: cover;
    @media #{$medium-up} {
      background: url('/media/loyalty/account-page-loyal-pc.jpg') no-repeat;
      background-size: contain;
      height: 26vw;
    }
    @media #{$xxlarge-up} {
      max-width: 1366px;
      height: 360px;
    }
  }
}
